.styled-input {
  width: 100%;
  position: relative;
  height: 40px;
  margin-bottom: 10px;

  input {
    width: 100%;
    height: 100%;
    border: none;
    border-bottom: 2px solid silver;
    font-size: 17px;
  }

  input:focus ~ label,
  input:valid ~ label {
    transform: translateY(-25px);
    font-size: 15px;
  }

  input:focus {
    outline: none;
  }

  label {
    position: absolute;
    bottom: -20px;
    left: 0;
    font-size: 20px;
    color: grey;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: all 0.3s ease;
  }

  .underline {
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 100%;
  }

  .underline:before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  input:focus ~ .underline:before,
  input:valid ~ .underline:before {
    transform: scaleX(1);
  }
}

.styled-select {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  label {
    font-size: 15px;
  }

  .select {
    .css-g1d714-ValueContainer {
      padding: 0;
      overflow: visible;
    }
    .css-1pahdxg-control {
      border: none;
      border-bottom: 2px solid silver;
      border-radius: 0;
      box-shadow: none;
    }
    .css-yk16xz-control {
      border: none;
      border-bottom: 2px solid silver;
      border-radius: 0;
    }
    .css-1wa3eu0-placeholder {
      margin: 0 0 -12px 0;
      font-size: 20px;
      transform: none;
      position: relative;
      font-weight: 400;
    }
    .css-b8ldur-Input {
      margin: 0 0 -15px 3px;
      padding: 0;
    }
    .css-1uccc91-singleValue {
      margin: 0 0 -12px 0;
      font-size: 20px;
      transform: none;
      position: relative;
      font-weight: 400;
    }
  }
}

.col-md-12 {
  .css-g1d714-ValueContainer {
    width: min-content;
  }
}
