@media (max-width: 1350px) {
  .remote-reading-container .cards-container .card .header-t .left-t h2 {
    font-size: 1.2rem;
  }
}

.cards-container {
  height: calc(100vh - 205px);
  min-height: 620px;
  margin-bottom: 20px;
  .flip {
    position: relative;
    height: calc(50% - 10px);
  }
}
