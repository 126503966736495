.sensor-graph-container {
  padding: 20px;

  &.dryc {
    .historique-container {
      border: 0;
      .title {
        border: 0;
        h2 {
          font-size: 20px;
          color: black;
        }
      }
    }
  }

  &.delta {
    .intervalle-sensor-container {
      border: 1px solid lightgrey;
      background-color: white;
      border-radius: 10px;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
    .best-date-component {
      width: 50%;
      padding: 10px 20px;
      margin-bottom: 0;
    }
    .charts-container .chart-container {
      margin-top: 0;
    }
  }
  .sensors-wrapper {
    padding: 20px;
    border: 1px solid lightgrey;
    background-color: white;
    border-radius: 10px;
  }
  .title {
    border-bottom: 2px solid lightgrey;
    width: fit-content;
  }
  h2 {
    color: #31c6b3;
    margin-bottom: 10px;
  }
  .charts-container {
    position: relative;
    .flex-between {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .separator {
      width: 75%;
      margin-left: 12.5%;
      height: 2px;
      background-color: lightgrey;
      margin-top: 25px;
    }
    .chart-container {
      position: relative;
      padding: 20px 20px 40px 20px;
      margin-top: 25px;
      canvas {
        height: 375px;
        max-height: 375px;
      }
    }
  }
  .sensor-container {
    .historique-container {
      border: none;
      .title {
        width: 100%;
      }
      h2 {
        font-weight: 300;
      }
    }
  }

  .info-container {
    display: flex;
    margin-bottom: 20px;
    .map {
      width: 65%;
      .map-container {
        height: 100%;
      }
    }
    .content {
      padding: 20px;
      .info-box {
        margin-bottom: 30px;
        span {
          color: #808080;
        }
        h3 {
          color: black;
        }
      }
    }
    .info-block {
      width: 35%;
      border: 1px solid lightgrey;
      background-color: white;
      border-radius: 10px;
      margin-right: 20px;
      .header {
        background-size: 800px;
        width: 100%;
        background: url(../_svgs/background_green.svg);
        background-size: 896px 1080px;
        min-height: 60px;
        border-radius: 10px 10px 0 0;
        display: flex;
        align-items: center;
        padding: 0 20px;
        .title {
          border-bottom: none;
        }
        span {
          color: white;
        }
        h4 {
          color: #2c303b;
          font-weight: 300;
          margin-bottom: 0;
        }
      }
    }
  }
  .unfold-sensor-alert {
    border: 1px solid lightgrey;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 10px;
    .title {
      width: 10%;
      min-width: 100px;
      border: 0;
      display: flex;
      h4 {
        margin: 0 0 0 15px;
        color: black;
      }
      svg {
        margin-left: 15px;
      }
    }
    .switch-section {
      min-width: 80px;
    }
    .section {
      width: 30%;
      min-width: 250px;
      display: flex;
      align-items: center;
      flex-direction: column;
      &.b {
        width: 20%;
      }
      .center {
        width: 75%;
      }
      .input-s {
        margin-right: 10px;
      }
    }
  }
}

.rouage-container-f {
  position: relative;
  display: flex;
  align-items: center;
  .svg-container {
    align-self: flex-end;
    cursor: pointer;
    transition: 2s;
    transform: rotate(0);
    padding: 8px;
    &.start {
      transform: rotate(-360deg);
    }
  }
  .relative-container {
    position: relative;
    height: 60px;
    width: 0;
    overflow: hidden;
    transition: 2s;
    &.start {
      width: 420px;
    }
  }
  .pop-up {
    position: absolute;
    background: white;
    border-radius: 5px;
    display: flex;
    left: 20px;
    width: 400px;
    .section {
      margin-right: 15px;
      margin-bottom: 5px;
      span {
        color: black;
        font-size: 16px;
      }
      &:nth-child(4) input {
        margin-bottom: 10px;
      }
      .dropdown button {
        width: 100%;
      }
    }
    .button-group {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .r-button {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #dc3545;
        border-radius: 50%;
        cursor: pointer;
        &:nth-child(2) {
          margin-left: 10px;
          background-color: #31c6b3;
        }
      }
    }
  }
}

.sensor-dashboard-container {
  background-color: white;
  border: 1px solid lightgrey;
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
  position: relative;

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    h2 {
      margin: 0 0 0 10px;
    }
  }

  .add-dashboard {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lightgrey;
    position: absolute;
    right: 20px;
    top: 20px;
    transition: 0.3s;
    cursor: pointer;
    &:hover {
      background-color: #34827a;
    }
  }
}

.sensor-dashboard-modal {
  padding: 20px;
  border: 1px solid lightgrey;
  background-color: white;
  border-radius: 10px;
  .modal-content {
    border: 0;
    .MuiFormControl-marginNormal {
      display: block;
      margin: 0;
    }
    .select-sensors {
      margin: 20px 0;
    }
    .sensor-modal-validation {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      button {
        background-color: #31c6b3;
        border-color: #31c6b3;
        &:nth-child(1) {
          background-color: #dc143c;
          border-color: #dc143c;
        }
      }
    }
  }
}

.sensor-cluster-container {
  padding: 20px;
  .main-container {
    border: 1px solid lightgrey;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    .title {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      .svg-dashboard {
        margin-right: 10px;
      }
      .radio-button {
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;
        border: 1px solid #31c6b3;
        cursor: pointer;
        &:nth-child(3) {
          border-radius: 0 0 5px 5px;
        }
        &:nth-child(4) {
          border-radius: 0 5px 5px 0;
        }
        &.on {
          background-color: #31c6b3;
          outline: 1px solid #31c6b3af;
        }
      }
      h2 {
        color: black;
        margin: 0 10px 0 0;
      }
    }
    .serial {
      color: #31c6b3;
      margin-bottom: 15px;
      width: fit-content;
      display: flex;
      align-items: center;
      cursor: pointer;
      .round-svg {
        background-color: #31c6b3;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      h4 {
        margin: 5px 0 0 10px;
        border-bottom: 1px solid grey;
      }
      &.flex {
        width: 100%;
      }
    }
    .chart-title {
      h5 {
        color: black;
      }
    }
    .chart-container {
      position: relative;
      max-height: 350px;
      margin: 0 0 10px 0;
      canvas {
        min-height: 350px;
        max-height: 350px;
      }
    }
    .maxWidth {
      max-width: 30%;
    }
    .sensors-container {
      position: relative;
      justify-content: space-evenly;
      flex-wrap: wrap;
      z-index: 1;
      .sensor-wrapper {
        min-width: 240px;
      }
      .fil-bar {
        height: 100%;
        position: absolute;
        background-color: #31c6b3;
        top: 0;
        left: 24px;
        width: 2px;
        z-index: -1;
      }
    }
  }
}
