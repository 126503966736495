#scrollable-table {
  overflow-x: scroll;
  table {
    overflow: unset;
  }
  .react-bootstrap-table {
    overflow: unset;
    .table thead th {
      position: sticky;
      top: 0;
      background-color: white;
    }
  }
  .react-bootstrap-table-pagination {
    margin: 0;
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    .col-md-6 {
      margin: 0;
      .dropdown-menu {
        left: 50px;
        top: 0;
        transform: translateY(-100%);
        margin-top: 26px;
      }
      .pagination {
        margin: 0;
      }
    }
  }
}
