.clean-meter-bar.indexWrapped {
  .button-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    .round-svg:nth-child(1) {
      margin-bottom: 5px;
      border-color: #dc3545;
      transition: 0.3s;
      svg {
        fill: #dc3545;
      }
      &:hover {
        background-color: #dc3545;
        svg {
          fill: #fff;
        }
      }
    }

    .round-svg:nth-child(2) {
      transition: 0.3s;
      border-color: #31c6b3;
      svg {
        fill: #31c6b3;
      }
      &:hover {
        background-color: #31c6b3;
        svg {
          fill: #fff;
        }
      }
    }
  }
  .button-group-disabled {
    filter: grayscale(1) opacity(0.5);
    .round-svg {
      cursor: not-allowed !important;
    }
  }
  .round-svg {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    border: 1px solid #333;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
      background-color: #333;
      svg {
        fill: #fff;
      }
    }
    svg {
      margin: 0;
    }
  }

  .roll-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .roll-block {
      height: 48px;
      width: 24px;
      background-color: #333;
      margin-right: 2px;

      display: flex;
      align-items: center;
    }
    input {
      /*
      position: absolute;
      width: calc(100% + 28px);
      letter-spacing: 18px;
      left: -17px;
      text-align: right;
      background-color: transparent;
      border: none;
      color: white;
      font-size: 1.2em;
      */
      width: 100%;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid lightgrey;
      color: white;
      font-size: 1.2em;
      text-align: center;
    }
    input:focus {
      outline: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
}

.row.meterInfo {
  .button-group {
    display: flex;
    justify-content: flex-end;
    .sticky-button {
      border: 1px solid;
      transition: 0.3s;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      span {
        display: none;
        color: white;
        margin-right: 5px;
      }
    }
    .sticky-button:nth-child(1) {
      background-color: #fff;
      border-radius: 5px 0 0 5px;
      border-color: #dc3545;
      svg {
        fill: #dc3545;
      }
      &:hover {
        background-color: #dc3545;
        width: 100px;
        svg {
          fill: #fff;
        }
        span {
          display: block;
        }
      }
    }
    .sticky-button:nth-child(2) {
      background-color: #fff;
      border-radius: 0 5px 5px 0;
      border-color: #31c6b3;
      svg {
        fill: #31c6b3;
      }
      &:hover {
        background-color: #31c6b3;
        width: 100px;
        svg {
          fill: #fff;
        }
        span {
          display: block;
        }
      }
    }
  }
}
