.message-com-container {
  height: 100%;
  h5 {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 25px;
  }
  .wifi-symbol {
    display: none;

    $circleColor: #31c6b3;
    $size: 60px;

    [foo],
    & {
      display: block;
      position: absolute;
      top: 25%;
      left: calc(50% + 50px);
      display: inline-block;
      width: $size;
      height: $size;
      margin-top: 0 - $size - $size * 0.25;

      -ms-transform: rotate(-45deg) translate(-100px);
      -moz-transform: rotate(-45deg) translate(-100px);
      -o-transform: rotate(-45deg) translate(-100px);
      -webkit-transform: rotate(-45deg) translate(-100px);
      transform: rotate(-45deg) translate(-100px);
    }
    .wifi-circle {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      display: block;
      width: 100%;
      height: 100%;
      font-size: $size/7;
      position: absolute;
      bottom: 0;
      left: 0;
      border-color: $circleColor;
      border-style: solid;
      border-width: 1em 1em 0 0;
      -webkit-border-radius: 0 100% 0 0;
      border-radius: 0 100% 0 0;

      opacity: 0;
      -o-animation: wifianimation 3s infinite;
      -moz-animation: wifianimation 3s infinite;
      -webkit-animation: wifianimation 3s infinite;
      animation: wifianimation 3s infinite;

      &.first {
        -o-animation-delay: 800ms;
        -moz-animation-delay: 800ms;
        -webkit-animation-delay: 800ms;
        animation-delay: 800ms;
      }
      &.second {
        width: 5em;
        height: 5em;
        -o-animation-delay: 400ms;
        -moz-animation-delay: 400ms;
        -webkit-animation-delay: 400ms;
        animation-delay: 400ms;
      }
      &.third {
        width: 3em;
        height: 3em;
      }
      &.fourth {
        width: 1em;
        height: 1em;
        opacity: 1;
        background-color: $circleColor;
        -o-animation: none;
        -moz-animation: none;
        -webkit-animation: none;
        animation: none;
      }
    }
  }

  @keyframes wifianimation {
    0% {
      opacity: 0.4;
    }
    5% {
      opactiy: 1;
    }
    6% {
      opactiy: 0.1;
    }
    100% {
      opactiy: 0.1;
    }
  }
}

div:has(> .node-wrapper) {
  overflow: unset !important;
}

.task-wrapper {
  height: 170px;
  width: 100%;
  border: 1px solid #31c6b3;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;

  .loading {
    h4,
    h5 {
      color: #31c6b3;
    }
  }

  h5 {
    color: black;
  }

  .event-wrapper {
    height: 100%;
  }

  .task-header {
    padding: 5px;
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      max-width: calc(100% - 80px);
      h3,
      h4 {
        margin: 0 10px;
      }

      h3 {
        color: #31c6b3;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      h4 {
        color: black;
      }
    }

    .draggable-icone-format {
      display: table-cell;
      height: 15px;

      ul {
        padding-left: 0;
      }

      li {
        list-style: none;
      }

      .draggable-icone-row {
        display: flex;
        margin-bottom: 2px;

        .drag-point {
          height: 3px;
          width: 3px;
          border-radius: 1.5px;
          background-color: #34827a;
          margin: 0 1px 0 1px;
        }
      }
    }
  }

  .task-body {
    height: calc(100% - 40px);
  }
  .round-info-container {
    display: flex;
  }

  .label-list-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    border-left: 1px solid lightgrey;
    .labels-container {
      overflow-x: hidden;
    }
    .waiting-action-button button {
      height: 100%;
    }
  }

  .label-container {
    padding: 2px;
    margin-bottom: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .left,
    .right {
      display: flex;
      align-items: center;
    }
    span {
      color: white;
      font-size: 12px;
      margin-left: 10px;
    }
    .hover-container {
      position: absolute;
      background-color: black;
      left: 50%;
      transform: translate(-50%, -26px);
      padding: 3px;
      border-radius: 3px;
      .pointe {
        position: absolute;
        left: 50%;
        top: 24px;
        height: 10px;
        width: 10px;
        background-color: black;
        transform: rotate(45deg) translate(-50%, 0);
      }
      span {
        width: 140px;
      }
    }
  }

  &.multi {
    background: red;
    position: relative;
  }

  &.multi::before,
  &.multi::after {
    content: "";
    position: absolute;
    height: 95%;
    width: 99%;
    background-color: #fff;
    border: 1px solid #31c6b3;
    border-radius: 10px;
  }

  &.multi::before {
    right: 15px;
    top: 0;
    transform: rotate(-3deg);
    z-index: -1;
  }

  &.multi::after {
    top: 5px;
    right: -5px;
    transform: rotate(3deg);
    z-index: -2;
  }
}
