.scroll-arrow {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  background-color: #d3d3d357;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &.right {
    left: calc(100% - 30px);
  }
  &.left {
    left: calc(0% + 30px);
  }
  h2 {
    border: none;
    margin: 0;
    padding: 0;
    font-weight: bold;
    color: #33333397;
    font-size: 40px;
  }
}

.react-bootstrap-table {
  overflow-x: scroll;
  max-width: 100%;
}
