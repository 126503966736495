.list-render-header {
  .filter-container {
    .column-selector-container {
      .row:nth-child(2) {
        flex-direction: column;
        display: block;
        padding-bottom: 35px;
        button {
          width: 100px;
          float: right;
          display: block;
          margin-top: 5px;
        }
      }
    }
    .row {
      margin: 10px 0 0 0;
      .col-md-11 {
        max-width: 100%;
        padding: 0;
      }
    }
  }
}
