html {
  scroll-behavior: smooth;
}

.remote-reading-container {
  width: calc(100vw - 275px);
  padding: 80px 10px 10px 10px;
  .second-date-component {
    position: fixed;
    top: 90px;
    left: 260px;
    background-color: white;
    z-index: 100;
    width: calc(100% - 260px);
    border: 1px solid lightgrey;
    padding: 10px;
    .form-group {
      margin-bottom: 0;
    }
    .title-date {
      align-self: center;
      min-width: -webkit-fit-content;
      min-width: -moz-fit-content;
      min-width: fit-content;
      width: 25%;
      display: flex;
      margin-right: 6%;
      h3 {
        margin: 0;
        color: black;
      }
      svg {
        margin: 0 40px 0 20px;
      }
    }
  }

  .flip-card-M {
    position: relative;
    width: calc(33.33% - 15px);
    height: 415px;
    margin-bottom: 20px;
    min-width: 300px;
    .table-info-container {
      border-color: lightgrey;
    }
  }
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .flip {
      position: relative;
      transform: rotateY(0deg);
      transition: 0.8s;
      margin-bottom: 20px;
      width: 33%;
      min-width: 300px;
      min-height: 300px;
      &.back {
        transform: rotateY(180deg);
        .card {
          transform: rotateY(-180deg);
        }
      }
    }
    .card {
      position: absolute;
      height: 100%;
      width: 100%;
      padding: 20px;
      border-radius: 15px;
      transition: 0.8s;
      &.un {
        z-index: 1;
      }
      &.zero {
        z-index: -1;
      }
      .header-t {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 20px;
        border-left: 3px solid #31c6b3;
        margin-left: -21px;
        .left-t {
          display: flex;
          align-items: center;
          svg {
            margin-right: 15px;
          }
          h2 {
            color: #2c303b;
            font-size: 1.5rem;
          }
        }
        .svg-container {
          height: 25px;
          width: 25px;
          border-radius: 50%;
          border: 1px solid lightgrey;
          background-color: lightgrey;
          transition: 0.3s;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &:hover {
            border-color: #34827a;
            background-color: #34827a;
          }
        }
        h2 {
          margin-bottom: 0;
        }
      }
      .synthese-container {
        margin-top: 20px;
        .h4-period {
          color: black;
        }
        span {
          display: flex;
          align-items: center;
          margin: 10px 0;
          h4 {
            font-weight: bold;
            margin: 0 10px;
          }
        }
      }
    }
  }
  .remote-map-container {
    border: 1px solid lightgrey;
    padding: 20px;
    background-color: white;
    border-radius: 15px;

    .react-bootstrap-table td.crystalList-column {
      white-space: break-spaces;
    }

    .header-o .flex-box-t {
      justify-content: space-between;
      align-items: center;
      display: flex;
      .title-o {
        display: flex;
        h2 {
          margin-bottom: 0;
          margin-left: 15px;
          color: black;
        }
      }
      button {
        border-radius: 50%;
        height: 50px;
        width: 50px;
        display: flex;
      }
    }

    .selection-t {
      margin: 10px 0;
      span {
        color: blue;
        text-decoration: underline;
        text-underline-position: auto;
        margin: 0;
        cursor: pointer;
        &:nth-child(1) {
          margin-right: 15px;
        }
      }
    }
    .all-options {
      .options-row {
        border-bottom: 1px solid lightgrey;
        padding: 10px 0;
        display: flex;
        align-items: center;
        &:nth-last-child(1) {
          border-bottom: 0;
        }
        .title-s {
          width: 15%;
          color: black;
        }
        .flex-checkbox {
          display: flex;
          align-items: center;
          .checkbox-container {
            position: relative;
            display: flex;
            align-items: center;
            &.withAlarm {
              border-right: 1px solid lightgrey;
            }
            &.notRead {
              margin-left: 10px;
            }
            label {
              display: flex;
              align-items: center;
              margin: 0 10px 0 0;
            }
            .form-check-input {
              position: relative;
              margin: 0 5px 0 0;
            }
          }
        }
      }
    }
    .multiple-choice {
      margin: 0 0 10px 0;
      color: black;
      text-align: right;
      font-size: 0.8em;
    }
  }
  .band-info-container {
    padding: 20px;
    border: 1px solid rgba(49, 198, 179, 0.5);
    background-color: rgba(49, 198, 179, 0.5);
    border-radius: 15px;
    color: black;
    span {
      font-size: 1.1em;
    }
  }
  .remote-map-container-b {
    border-radius: 15px;
    overflow: hidden;
    margin-top: 20px;
    .clean-meter-bar {
      border-radius: 15px;
    }
    #map {
      .hide {
        opacity: 0;
      }
    }
  }
}
